import { connect } from 'react-redux';
import Decision from '../components/recommendation/Decision';
import { setDecision, setDecisionContent } from '../state/actions/decision';

const otherFactorsPlaceholder = 'Type here...';

const mapStateToProps = state => ({
  otherFactorsContent: state.getIn(['decision', 'otherFactorsContent'], otherFactorsPlaceholder),
  decision: state.getIn(['decision', 'decision'], null),
});

const mapDispatchToProps = dispatch => ({
  setDecision: value => () => dispatch(setDecision(value)),
  setDecisionContent: e => dispatch(setDecisionContent(e.target.innerHTML)),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(Decision);
