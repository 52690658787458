import React from "react"
import Immutable from "immutable"
import _ from "underscore"
import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd"
import { connect } from "react-redux"
import { array, instanceOf, string } from "prop-types"
import {
  moveSummaryOutcome,
  groupOutcomes,
} from "../../state/actions/summaryOutcomesActions"
import ContentBox from "../common/ContentBox"
import styles from "../../styles/summary.module.styl"
import textStyles from "../../styles/texts.module.styl"
import contentStyles from "../../styles/content.module.styl"
import componentStyles from "../../styles/components.module.styl"
import AssessmentValueBox from "./AssessmentValueBox"
import Circle from "../common/Circle"
import RangeInput from "../common/RangeInput"

const SUMMARY_STATUS_TYPE_MAP = {
  accepted: "blue",
  rejected: "orange",
  notImportant: "gray",
}

const SmallCircle = ({ currentValue }) => (
  <Circle
    className={componentStyles.rangeInputToggleDisabled}
    content={currentValue !== null ? currentValue : ""}
    size={30}
  />
)

// TODO: maybe highlight group onhover

const Outcome = ({ data, index }) => {
  const status = data.get("status")
  const boxType = SUMMARY_STATUS_TYPE_MAP[status]
  const name = data.get("name")
  const description = data.get("description")
  const value = data.get("value")
  const id = data.get("id")

  return (
    <Draggable draggableId={`${id}#${status}`} index={index} key={id}>
      {(providedDraggable, snapshot) => (
        <div>
          <div
            ref={providedDraggable.innerRef}
            {...providedDraggable.draggableProps}
            {...providedDraggable.dragHandleProps}
            className={styles.assessmentBoxOutcome}
          >
            <AssessmentValueBox key={name} header={description} type={boxType}>
              <div>
                Your value: <span className={textStyles.blueText}>{name}</span>
              </div>
              <RangeInput
                min={0}
                max={100}
                value={value}
                tickMarkStep={50}
                Toggle={SmallCircle}
                withToggleArrows={false}
                showTickMarkValues={false}
                readonly
              />
            </AssessmentValueBox>
          </div>
          {providedDraggable.placeholder}
        </div>
      )}
    </Draggable>
  )
}

class OutcomesList extends React.PureComponent {
  static propTypes = {
    outcomes: instanceOf(Immutable.List).isRequired,
  }

  render() {
    return (
      <div className={styles.assessmentBoxOutcomesList}>
        {this.props.outcomes.map((outcome, idx) => (
          <Outcome data={outcome} index={idx} key={outcome.get("id")} />
        ))}
      </div>
    )
  }
}

const OutcomesGroup = ({ outcomes, groupName, isDropDisabled }) => (
  <Droppable droppableId={groupName} isDropDisabled={isDropDisabled}>
    {(providedDroppable, snapshot) => (
      <div
        ref={providedDroppable.innerRef}
        {...providedDroppable.droppableProps}
        style={{
          width: "311px",
          margin: "0 auto",
          flexGrow: "1",
        }}
      >
        {groupName === "notImportant" && outcomes.isEmpty() ? (
          <h3 className={contentStyles.hideOnPrint}>
            Drag And Drop Outcome Here
          </h3>
        ) : (
          <OutcomesList outcomes={outcomes} />
        )}
      </div>
    )}
  </Droppable>
)

class AssessmentBox extends React.PureComponent {
  static propTypes = {
    acceptedOutcomes: instanceOf(Immutable.List),
    intervention: string.isRequired,
    notImportantOutcomes: instanceOf(Immutable.List),
    outcomes: array.isRequired,
    rejectedOutcomes: instanceOf(Immutable.List),
  }

  static defaultProps = {
    acceptedOutcomes: Immutable.List(),
    rejectedOutcomes: Immutable.List(),
    notImportantOutcomes: Immutable.List(),
  }

  constructor(props) {
    super(props)
    this.state = {}
    this.onDragEnd = this.onDragEnd.bind(this)
    this.onDragStart = this.onDragStart.bind(this)
  }

  onDragStart(data) {
    // eslint-disable-next-line no-unused-vars
    const [_outcomeId, status] = data.draggableId.split("#")
    this.setState({ draggingItemStatus: status })
  }

  onDragEnd(dropResult) {
    const { source, destination, reason } = dropResult
    if (reason !== "DROP" || destination == null) return
    const { droppableId: fromGroup, index: fromIndex } = source
    const { droppableId: toGroup, index: toIndex } = destination

    this.setState({ draggingItemStatus: null })
    return this.props.moveOutcome({ fromGroup, fromIndex, toGroup, toIndex })
  }

  componentDidMount() {
    this.props.groupOutcomes(this.props.outcomes)
  }

  render() {
    const {
      acceptedOutcomes,
      intervention,
      notImportantOutcomes,
      rejectedOutcomes,
    } = this.props
    const { draggingItemStatus } = this.state

    return (
      <DragDropContext
        onDragEnd={this.onDragEnd}
        onDragStart={this.onDragStart}
      >
        <ContentBox className={styles.assessmentBox}>
          <div className={styles.assessmentBoxGroup}>
            <div
              className={styles.assessmentBoxGroupTitle}
            >{`Against ${intervention}`}</div>
            <OutcomesGroup
              groupName="rejected"
              outcomes={rejectedOutcomes}
              isDropDisabled={draggingItemStatus !== "rejected"}
            />
          </div>
          <div className={styles.assessmentBoxGroupAgainst}>
            <div
              className={styles.assessmentBoxGroupTitleAgainst}
            >{`For ${intervention}`}</div>
            <OutcomesGroup
              groupName="accepted"
              outcomes={acceptedOutcomes}
              isDropDisabled={draggingItemStatus !== "accepted"}
            />
          </div>
          <div className={styles.assessmentBoxGroupExcluded}>
            <h3 className={contentStyles.hideOnPrint}>
              If necessary, put here less important outcomes that you do not
              want to take into consideration
            </h3>
            <div className={styles.assessmentBoxExcludedDropArea}>
              <OutcomesGroup
                groupName="notImportant"
                outcomes={notImportantOutcomes}
              />
            </div>
          </div>
        </ContentBox>
      </DragDropContext>
    )
  }
}

const mapStateToProps = state => ({
  acceptedOutcomes: state.getIn(["summaryOutcomes", "accepted"]),
  rejectedOutcomes: state.getIn(["summaryOutcomes", "rejected"]),
  notImportantOutcomes: state.getIn(["summaryOutcomes", "notImportant"]),
})

const mapDispatchToProps = dispatch => ({
  moveOutcome: _.compose(dispatch, moveSummaryOutcome),
  groupOutcomes: _.compose(dispatch, groupOutcomes),
})

const connectedAssessmentBox = connect(
  mapStateToProps,
  mapDispatchToProps
)(AssessmentBox)

export default connectedAssessmentBox
