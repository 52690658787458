import React from "react"
import { kebabCase } from "lodash"
import { generateColorMap } from "../../lib/utils"
import styles from "../../styles/content.module.styl"
import rcStyles from "../../styles/riskCalculator.module.styl"
import ContentBox from "../common/ContentBox"
import RiskIndicatorIcon from "./RiskIndicatorIcon"

const GRADIENT_START = "#00a908"
const GRADIENT_MEDIAN = "#e9d600"
const GRADIENT_END = "#fe564b"

export const RiskIndicator = ({ riskLabels, riskValue }) => {
  const colorMap = generateColorMap(
    GRADIENT_START,
    GRADIENT_MEDIAN,
    GRADIENT_END,
    101
  )

  const riskInterval = Math.ceil((riskValue / 100) * riskLabels.length) || 1

  const currentRisk = riskLabels[riskInterval - 1]
  const currentRiskHeader = currentRisk.content.replace(
    "__color__",
    `#${colorMap[riskValue]}`
  )
  return (
    <div>
      <h2>Your risk</h2>
      <ContentBox>
        <div className={styles.textCenter}>
          <div
            className={rcStyles.riskHeader}
            dangerouslySetInnerHTML={{ __html: currentRiskHeader }}
          />
          <div
            className={rcStyles.riskDescription}
            dangerouslySetInnerHTML={{ __html: currentRisk.description }}
          />
        </div>
        <div className={rcStyles.rcGradient}>
          <div
            className={rcStyles.riskIndicator}
            style={{ left: `calc(${riskValue}% - 20px)` }}
          >
            <RiskIndicatorIcon
              colorCircle={colorMap[riskValue]}
              colorBg={colorMap[riskValue]}
            />
          </div>
        </div>
        <div className={rcStyles.labels}>
          {riskLabels.map((riskLabel, index) => {
            const textColor =
              index === riskInterval - 1 ? `#${colorMap[riskValue]}` : "#666"
            return (
              <span
                key={kebabCase(riskLabel.label)}
                style={{ color: textColor }}
              >
                {riskLabel.label}
              </span>
            )
          })}
        </div>
      </ContentBox>
    </div>
  )
}
