import React from 'react';
import { string, func } from 'prop-types';

class ContentEditable extends React.Component {
  static propTypes = {
    content: string,
    placeholder: string,
    onInput: func.isRequired,
    onFocus: func,
    onBlur: func,
    className: string,
  };

  static defaultProps = {
    content: '',
    placeholder: '',
    onFocus: null,
    onBlur: null,
    className: '',
  };

  constructor(props) {
    super(props);
    this.contentEditableRef = this.contentEditableRef.bind(this);
  }

  contentEditableRef(e) {
    this.contentEditable = e;
  }

  shouldComponentUpdate(nextProps) {
    return nextProps.content !== this.contentEditable.innerHTML;
  }

  componentDidUpdate() {
    if (this.props.content !== this.contentEditable.innerHTML) {
      this.contentEditable.innerHTML = this.props.content;
    }
  }

  render() {
    return (
      <div
        ref={this.contentEditableRef}
        className={this.props.className}
        contentEditable
        onFocus={this.props.onFocus}
        onInput={this.props.onInput}
        onBlur={this.props.onBlur}
        dangerouslySetInnerHTML={{ __html: this.props.content }}
      />
    );
  }
}

export default ContentEditable;
