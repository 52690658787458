import Immutable from 'immutable';
import React from 'react';

import { connect } from 'react-redux';
import Assessment from '../components/summary/AssessmentBox';

const mapStateToProps = state => ({
  outcomeValues: state.get('outcomeValues'),
});

const AssessmentBox = ({ assessmentMarkerStates, markerStates, intervention, outcomeValues }) => {
  const outcomes = assessmentMarkerStates.reduce((acc, ms) => {
    const markerStateData = markerStates.find(marker => marker.get('key') === ms);
    acc = acc.push({
      id: ms,
      name: markerStateData.get('explanationHeader'),
      status: markerStateData.get('forIntervention') ? 'accepted' : 'rejected',
      description: markerStateData.get('summaryTitle'),
      value: outcomeValues.get(ms, 50),
    });
    return acc;
  }, Immutable.List());

  return (
    <div>
      <Assessment outcomes={outcomes.toJS()} intervention={intervention} />
    </div>
  );
};

export default connect(mapStateToProps)(AssessmentBox);
