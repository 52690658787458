import Immutable from "immutable"
import { kebabCase } from "lodash"
import React from "react"
import { connect } from "react-redux"
import _ from "underscore"
import ContentBox from "../components/common/ContentBox"
import HeaderWithIcon from "../components/common/HeaderWithIcon"
import Section from "../components/common/Section"
import Layout from "../components/layout"
import SubPageHeader from "../components/header/SubPageHeader"
import ArrowNavigation from "../components/navigation/ArrowNavigation"
import PanelRecommendation from "../components/recommendation/PanelRecommendation"
import { RiskIndicator } from "../components/riskCalculator/RiskIndicator"
import AssessmentBox from "../containers/AssessmentBox"
import Decision from "../containers/Decision"
import { calculatePercentValue, getLinkToSubpage } from "../lib/utils"
import btnStyles from "../styles/buttons.module.styl"
import componentStyle from "../styles/components.module.styl"
import styles from "../styles/content.module.styl"

const calculateRisk = (questions, selectedRisks) => {
  return questions.reduce((acc, question) => {
    const qKey = kebabCase(question.content)
    if (selectedRisks.has(qKey) && selectedRisks.get(qKey) === "yes") {
      return (acc += question.value)
    }
    return acc
  }, 0)
}

const mapStateToProps = state => ({
  selectedRisks: state.getIn(["riskCalculator", "selectedRisks"]),
})

const Summary = ({ selectedRisks, location, data, pathContext }) => {
  const nodeData = data.decisionAidsJson
  const { pageId, groupId, subPage, group } = pathContext

  const options = Immutable.fromJS(nodeData.decision.options).reduce(
    (acc, option) => {
      acc = acc.push(Immutable.Map({ value: kebabCase(option), label: option }))
      return acc
    },
    Immutable.List()
  )
  const prevLink = getLinkToSubpage(pageId, groupId, "options")
  const markerStates = Immutable.fromJS(nodeData.markerStates)
  const recommendation = groupId
    ? group.recommendation
    : nodeData.recommendation

  return (
    <Layout>
      <SubPageHeader
        title={nodeData.title}
        group={group}
        hasGroups={!_.isEmpty(nodeData.groups)}
        location={location}
        logo={nodeData.logo}
        pageId={pageId}
        groupId={groupId}
        subPage={subPage}
      />
      <div id="printable-content" className={styles.content}>
        {!selectedRisks.isEmpty() && (
          <div className={styles.avoidBreak}>
            <RiskIndicator
              riskLabels={nodeData.riskLabels}
              riskValue={calculatePercentValue(
                calculateRisk(nodeData.riskQuestions, selectedRisks),
                nodeData.riskQuestions.length
              )}
            />
          </div>
        )}
        <div className={styles.avoidBreak}>
          <HeaderWithIcon type="summary" title="Summary" />
          <Section
            section={Immutable.fromJS({
              title: nodeData.assessmentSummary.title,
              content: nodeData.assessmentSummary.content,
            })}
          />
          <AssessmentBox
            assessmentMarkerStates={Immutable.fromJS(
              nodeData.assessmentSummary.markerStates
            )}
            markerStates={markerStates}
            intervention={
              nodeData.researchResults.outcomesComparison.intervention
            }
          />
        </div>
        <div className={styles.avoidBreak}>
          <h1>Recommendations</h1>
          <ContentBox className={componentStyle.orangeContentBox}>
            <PanelRecommendation
              title={recommendation.title}
              recommendation={recommendation.content}
            />
            <Decision
              otherFactorsDescription={
                nodeData.decision.otherFactorsDescription
              }
              options={options}
              decisionDescription={nodeData.decision.description}
            />
          </ContentBox>
        </div>

        <button className={btnStyles.printBtn} onClick={() => window.print()}>
          Print
        </button>

        <ArrowNavigation prevLink={prevLink} />
      </div>
    </Layout>
  )
}

export default connect(mapStateToProps)(Summary)

export const query = graphql`
  query SummaryQuery($pageId: String) {
    decisionAidsJson(pageId: { eq: $pageId }) {
      title
      logo
      riskLabels {
        label
        content
        description
      }
      riskQuestions {
        content
        value
      }
      researchResults {
        outcomesComparison {
          intervention
          comparison
          outcomes {
            name
            choose
          }
        }
      }
      recommendation {
        title
        content
      }
      markerStates {
        key
        marker
        forIntervention
        summaryTitle
      }
      assessmentSummary {
        title
        content
        markerStates
      }

      decision {
        description
        options
        otherFactorsDescription
      }
    }
  }
`
