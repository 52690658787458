import classNames from 'classnames';
import React from 'react';

import { string, node, oneOf } from 'prop-types';
import styles from '../../styles/summary.module.styl';

const AssessmentValueBox = ({ type, header, description, children }) => (
  <div className={classNames(styles.assessmentValueBox, styles[`${type}AssessmentValueBox`])}>
    <div className={styles.assessmentValueBoxContent}>
      {header && <h4 className={styles.assessmentValueBoxHeader}>{header}</h4>}
      {description && <div>{description}</div>}
    </div>
    {children && <div className={styles.assessmentValueBoxAdditionalContent}>{children}</div>}
  </div>
);

AssessmentValueBox.propTypes = {
  children: node,
  description: string,
  header: string,
  type: oneOf(['orange', 'blue', 'gray', 'pale']),
};

AssessmentValueBox.defaultProps = {
  children: null,
  description: null,
  header: null,
  type: 'gray',
};

export default AssessmentValueBox;
