import React from "react"

const RiskIndicatorIcon = ({ colorCircle, colorBg }) => (
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 49.25 49.25">
    <defs>
      <style>{`.cls-1{fill:#${colorCircle};}.cls-2{fill:#fff;}.cls-3{fill:#${colorBg}}.cls-4{fill:#ef5168;}`}</style>
    </defs>
    <g id="Warstwa_2" data-name="Warstwa 2">
      <g id="Layer_1" data-name="Layer 1">
        <path
          className="cls-1"
          d="M17.44,1.08A24.62,24.62,0,1,0,48.17,17.44,24.61,24.61,0,0,0,17.44,1.08Zm13,42.48A19.8,19.8,0,1,1,43.56,18.85,19.8,19.8,0,0,1,30.4,43.56Z"
        />
        <circle className="cls-2" cx="24.62" cy="24.62" r="20.46" />
        <circle className="cls-3" cx="24.31" cy="24.72" r="17.13" />
        <circle className="cls-2" cx="24.54" cy="14.43" r="2.57" />
        <path
          className="cls-2"
          d="M35.91,19.5,24.62,17.85a1,1,0,0,0-.24,0h-.11L13,19a1,1,0,0,0,.21,2.08l7.88-.8-.17,7.6,0,1-.16,7.45a1.27,1.27,0,1,0,2.53.06l.16-7.45,1.68,0-.17,7.46a1.27,1.27,0,0,0,2.54.05L27.59,29l0-1,.17-7.59,7.83,1.14a1,1,0,1,0,.3-2.06Z"
        />
        <path
          className="cls-4"
          d="M26.65,20.78c-.53-.9-1.49-.21-1.49-.21s-.92-.73-1.49.15,1.44,2.47,1.44,2.47S27.21,21.72,26.65,20.78Z"
        />
      </g>
    </g>
  </svg>
)

export default RiskIndicatorIcon
