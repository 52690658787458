import { SET_DECISION, SET_OTHER_FACTORS_CONTENT } from './actionTypes';

export const setDecision = value => {
  return {
    type: SET_DECISION,
    value,
  };
};

export const setDecisionContent = content => {
  return { type: SET_OTHER_FACTORS_CONTENT, content };
};
